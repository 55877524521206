<template>
    <div class="flex justify-content-start align-items-center">
        <ek-dialog
            btnText="سؤال جديد"
            ref="dialog"
            :title="'اضافة سؤال جديد'"
            @close="cancelDialog"
            @ok="submit()"
            @search="setSearch"
        >
            <template #body>
                <validationObserver ref="classForm">
                    <b-form>
                        <ek-input-text
                            v-model="courseQDto.text"
                            name=" عنوان السؤال"
                            label=" عنوان السؤال"
                            :rules="[
                                {
                                    type: 'required',
                                    message: ' عنوان السؤال مطلوب',
                                },
                            ]"
                            placeholder="عنوان السؤال مطلوب"
                        ></ek-input-text>
                        <ek-input-select
                            name="previousExamId"
                            placeholder="تابع للدورة"
                            label="تابع للدورة"
                            :options="previousExamsName"
                            v-model="courseQDto.previousExamId"
                        ></ek-input-select>
                        <ek-input-text
                            name="  ترتيب السؤال"
                            label="المحافظة على ترتيب الاسئلة"
                            ref="order"
                            type="number"
                            v-model="courseQDto.group"
                            placeholder=" المحافظة على ترتيب الاسئلة "
                        ></ek-input-text>

                        <label>صورة السؤال</label>
                        <ek-input-image
                            v-if="!fileUrl"
                            :image="
                                fileUrl
                                    ? $store.getters['app/domainHost'] +
                                      '/' +
                                      fileUrl
                                    : ''
                            "
                            name="img"
                            ref="imgFile"
                            @input="storeFile($event)"
                        >
                            <h5>اسحب الملف او انقر للتحميل</h5>
                        </ek-input-image>
                        <div v-else class="position-relative">
                            <div
                                class="position-absolute"
                                :style="{
                                    'z-index': '999',
                                    left: '9px',
                                    top: '-4px',
                                }"
                            >
                                <unicon
                                    fill="red"
                                    height="30px"
                                    name="trash-alt"
                                    @click="fileUrl = null"
                                    class="mt-1 cursor-pointer"
                                >
                                </unicon>
                            </div>
                            <img
                                class="w-100 object-fit-contain"
                                :src="
                                    $store.getters['app/domainHost'] +
                                    '/' +
                                    fileUrl
                                "
                                style="height: 200px"
                            />
                        </div>
                        <ek-input-text
                            name=" التلميح "
                            label="التلميح"
                            ref="order"
                            v-model="courseQDto.hint.text"
                            placeholder=" التلميح "
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'اضافة التلميح للإختبار مطلوب',
                                },
                            ]"
                        ></ek-input-text>
                        <label>صورة التلميح</label>
                        <ek-input-image
                            v-if="!hintfileUrl"
                            :image="
                                hintfileUrl
                                    ? $store.getters['app/domainHost'] +
                                      '/' +
                                      hintfileUrl
                                    : ''
                            "
                            accept="image/png, image/jpeg"
                            name="imgHint"
                            ref="imgHintFile"
                            @input="UploadFile($event)"
                        >
                            <h5 v-if="!hintfileUrl">
                                اسحب الملف او انقر للتحميل
                            </h5>
                        </ek-input-image>
                        <div v-else class="position-relative">
                            <div
                                class="position-absolute"
                                :style="{
                                    'z-index': '999',
                                    left: '9px',
                                    top: '-4px',
                                }"
                            >
                                <unicon
                                    fill="red"
                                    height="30px"
                                    name="trash-alt"
                                    @click="hintfileUrl = null"
                                    class="mt-1 cursor-pointer"
                                >
                                </unicon>
                            </div>
                            <img
                                class="w-100 object-fit-contain"
                                :src="
                                    $store.getters['app/domainHost'] +
                                    '/' +
                                    hintfileUrl
                                "
                                style="height: 200px"
                            />
                        </div>
                        <div class="position-relative w-100 h-50 mt-2">
                            <div v-for="(tag, i) in courseQDto.tags" :key="i">
                                <div
                                    class="-mt-2 flex-column align-content-center justify-content-center w-100"
                                >
                                    <div class="lable">
                                        <p style="margin-bottom: -15px">
                                            {{ "وسم" + " " + (i + 1) }}
                                        </p>
                                        <div>
                                            <unicon
                                                fill="var(--main-color)"
                                                height="25px"
                                                name="trash-alt"
                                                v-if="i > 0"
                                                @click="
                                                    deleteTags(
                                                        courseQDto.tags,
                                                        i
                                                    )
                                                "
                                                class="mt-1 cursor-pointer"
                                            >
                                            </unicon>
                                            <unicon
                                                @click="
                                                    addTags(courseQDto.tags)
                                                "
                                                v-if="i === 0"
                                                fill="var(--main-color)"
                                                height="25px"
                                                name="plus"
                                                class="mt-1 cursor-pointer"
                                            ></unicon>
                                        </div>
                                    </div>
                                    <ek-input-text
                                        ref="order"
                                        v-model="courseQDto.tags[i]"
                                        :name="'وسم' + i"
                                        style="width: 80%"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message:
                                                    'اضافة وسم للإختبار مطلوب',
                                            },
                                        ]"
                                    ></ek-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="position-relative w-100 h-50 mt-2">
                            <div
                                v-for="(tag, i) in courseQDto.answers"
                                :key="i"
                            >
                                <div
                                    class="-mt-2 flex-column align-content-center justify-content-center w-100"
                                >
                                    <div class="lable">
                                        <p style="margin-bottom: -15px">
                                            {{ "جواب" + " " + (i + 1) }}
                                        </p>
                                        <div>
                                            <unicon
                                                fill="var(--main-color)"
                                                height="25px"
                                                name="trash-alt"
                                                v-if="i > 0"
                                                @click="
                                                    deleteAnswers(
                                                        courseQDto.answers,
                                                        i
                                                    )
                                                "
                                                class="mt-1 cursor-pointer"
                                            >
                                            </unicon>
                                            <unicon
                                                @click="
                                                    addAnswers(
                                                        courseQDto.answers
                                                    )
                                                "
                                                v-if="i === 0"
                                                fill="var(--main-color)"
                                                height="25px"
                                                name="plus"
                                                class="mt-1 cursor-pointer"
                                            ></unicon>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex justify-content-between align-content-center"
                                    >
                                        <!-- <b-form-checkbox
                                            v-model="
                                                courseQDto.answers[i].isCorrect
                                            "
                                            class="mr-0 -mt-1"
                                            name="صحيح"
                                            switch
                                            inline
                                        /> -->
                                        <ek-input-text
                                            ref="order"
                                            v-model="courseQDto.answers[i].text"
                                            :name="'جواب' + (i + 1)"
                                            style="width: 65%"
                                            :placeholder="'جواب' + i"
                                            :rules="[
                                                {
                                                    type: 'required',
                                                    message:
                                                        'اضافة جواب للإختبار مطلوب',
                                                },
                                            ]"
                                        ></ek-input-text>
                                        <div
                                            class="d-flex justify-content-start align-content-center"
                                        >
                                            <b-form-checkbox
                                                v-model="
                                                    courseQDto.answers[i]
                                                        .isCorrect
                                                "
                                                class="mx-2 mt-6"
                                                name="صحيح"
                                                lable="صحيح"
                                                switch
                                                inline
                                                style="margin-top: 20px"
                                            />
                                            <p
                                                v-if="
                                                    courseQDto.answers[i]
                                                        .isCorrect
                                                "
                                                style="
                                                    margin-top: 20px;
                                                    margin-right: -15px;
                                                    color: var(--main-color);
                                                "
                                            >
                                                صحيح
                                            </p>
                                            <p
                                                v-if="
                                                    !courseQDto.answers[i]
                                                        .isCorrect
                                                "
                                                style="
                                                    margin-top: 20px;
                                                    margin-right: -15px;
                                                    color: red;
                                                "
                                            >
                                                خطأ
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </validationObserver>
            </template>
        </ek-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            fileUrl: null,
            hintfileUrl: null,
            file: null,
            SubjectId: null,
        };
    },

    props: {
        id: String,
    },
    computed: {
        ...mapState({
            isDialogOpen: (state) => state.courseQuestion.isDialogOpen,
            courseQDto: (state) => state.courseQuestion.courseQDto,
            previousExam: (state) => state.courseQuestion.previousExam,
            subjectsNames: ({ subjects }) => subjects.subjectsNames,
            previousExamsName: ({ previousExams }) =>
                previousExams.previousExamsName,
        }),
        ...mapGetters(["getUrl", "getSubjectsNamesGetter"]),
    },

    methods: {
        ...mapActions([
            "addFile",
            "addCourseQuestions",
            "getPreviousExamNames",
            "getSubjectsNames",
            "AddFromExcel",
            "AddFromSchoolExcel",
            "getSubjects",
            "getDetailsPreviousExams",
        ]),

        editCourseQuestionsList() {
            this.$store.commit("IS_DIALOG_OPEN", true);
        },

        cancelDialog() {
            this.$refs.dialog.close();
            this.$refs.classForm.reset();
            this.$store.commit("Reset_Dto");
            this.$store.commit("IS_DIALOG_OPEN", false);
            this.fileUrl = null;
            this.hintfileUrl = null;
        },
        storeExcelFile(event) {
            console.log(event);
            if (event && event.file) {
                this.file = event.file;
            }
        },

        submit() {
            this.$refs.classForm.validate().then((suc) => {
                if (suc) {
                    this.addCourseQuestions({
                        ...this.courseQDto,
                        fileUrl: this.fileUrl,
                        hint: {
                            text: this.courseQDto.hint.text,
                            fileUrl: this.hintfileUrl,
                        },
                    })
                        .then((data) => {
                            console.log(data);
                            this.$refs.dialog.close();
                            if (this.$route.params.id != null) {
                                this.getDetailsPreviousExams(
                                    this.$route.params.id
                                );
                            }
                        })
                        .catch(() => {});
                }
            });
        },
        storeFile(event) {
            if (event && event.file) {
                this.addFile({
                    file: event.file,
                    folderName: "CourseQuestion",
                }).then((res) => {
                    this.courseQDto.fileUrl = res.data;
                    this.fileUrl = res.data;
                });
            }
        },
        UploadFile(event) {
            if (event && event.file) {
                this.addFile({
                    file: event.file,
                    folderName: "CourseQuestion",
                }).then((res) => {
                    this.hintfileUrl = res.data;
                    this.courseQDto.hint.fileUrl = res.data;
                });
            }
        },
        deleteTags(item, id) {
            const s = item.findIndex((ele, index) => index === id);

            item = item.splice(s, 1);
        },
        addTags(target) {
            target.push("");
        },
        deleteAnswers(item, id) {
            const s = item.findIndex((ele, index) => index === id);

            item = item.splice(s, 1);
        },
        addAnswers(target) {
            target.push({
                text: "",
                isCorrect: false,
            });
        },
    },
    created() {
        this.getPreviousExamNames().then(() => {
            if (this.$route.params.id != null) {
                this.courseQDto.previousExamId = this.$route.params.id;
            }
        });
        this.getSubjectsNames();
    },
    setSearch(query) {
        this.$store.commit("Set_Search_Dto", {
            keys: ["text"],
            query,
        });
    },
    watch: {
        isDialogOpen(nv) {
            if (nv) {
                this.$refs.dialog.open();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.lable {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
    margin-bottom: -10px !important;
}
</style>
