var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-content-start align-items-center"},[_c('ek-dialog',{ref:"dialog",attrs:{"btnText":"سؤال جديد","title":'اضافة سؤال جديد'},on:{"close":_vm.cancelDialog,"ok":function($event){return _vm.submit()},"search":_vm.setSearch},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('validationObserver',{ref:"classForm"},[_c('b-form',[_c('ek-input-text',{attrs:{"name":" عنوان السؤال","label":" عنوان السؤال","rules":[
                            {
                                type: 'required',
                                message: ' عنوان السؤال مطلوب',
                            } ],"placeholder":"عنوان السؤال مطلوب"},model:{value:(_vm.courseQDto.text),callback:function ($$v) {_vm.$set(_vm.courseQDto, "text", $$v)},expression:"courseQDto.text"}}),_c('ek-input-select',{attrs:{"name":"previousExamId","placeholder":"تابع للدورة","label":"تابع للدورة","options":_vm.previousExamsName},model:{value:(_vm.courseQDto.previousExamId),callback:function ($$v) {_vm.$set(_vm.courseQDto, "previousExamId", $$v)},expression:"courseQDto.previousExamId"}}),_c('ek-input-text',{ref:"order",attrs:{"name":"  ترتيب السؤال","label":"المحافظة على ترتيب الاسئلة","type":"number","placeholder":" المحافظة على ترتيب الاسئلة "},model:{value:(_vm.courseQDto.group),callback:function ($$v) {_vm.$set(_vm.courseQDto, "group", $$v)},expression:"courseQDto.group"}}),_c('label',[_vm._v("صورة السؤال")]),(!_vm.fileUrl)?_c('ek-input-image',{ref:"imgFile",attrs:{"image":_vm.fileUrl
                                ? _vm.$store.getters['app/domainHost'] +
                                  '/' +
                                  _vm.fileUrl
                                : '',"name":"img"},on:{"input":function($event){return _vm.storeFile($event)}}},[_c('h5',[_vm._v("اسحب الملف او انقر للتحميل")])]):_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"position-absolute",style:({
                                'z-index': '999',
                                left: '9px',
                                top: '-4px',
                            })},[_c('unicon',{staticClass:"mt-1 cursor-pointer",attrs:{"fill":"red","height":"30px","name":"trash-alt"},on:{"click":function($event){_vm.fileUrl = null}}})],1),_c('img',{staticClass:"w-100 object-fit-contain",staticStyle:{"height":"200px"},attrs:{"src":_vm.$store.getters['app/domainHost'] +
                                '/' +
                                _vm.fileUrl}})]),_c('ek-input-text',{ref:"order",attrs:{"name":" التلميح ","label":"التلميح","placeholder":" التلميح ","rules":[
                            {
                                type: 'required',
                                message: 'اضافة التلميح للإختبار مطلوب',
                            } ]},model:{value:(_vm.courseQDto.hint.text),callback:function ($$v) {_vm.$set(_vm.courseQDto.hint, "text", $$v)},expression:"courseQDto.hint.text"}}),_c('label',[_vm._v("صورة التلميح")]),(!_vm.hintfileUrl)?_c('ek-input-image',{ref:"imgHintFile",attrs:{"image":_vm.hintfileUrl
                                ? _vm.$store.getters['app/domainHost'] +
                                  '/' +
                                  _vm.hintfileUrl
                                : '',"accept":"image/png, image/jpeg","name":"imgHint"},on:{"input":function($event){return _vm.UploadFile($event)}}},[(!_vm.hintfileUrl)?_c('h5',[_vm._v(" اسحب الملف او انقر للتحميل ")]):_vm._e()]):_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"position-absolute",style:({
                                'z-index': '999',
                                left: '9px',
                                top: '-4px',
                            })},[_c('unicon',{staticClass:"mt-1 cursor-pointer",attrs:{"fill":"red","height":"30px","name":"trash-alt"},on:{"click":function($event){_vm.hintfileUrl = null}}})],1),_c('img',{staticClass:"w-100 object-fit-contain",staticStyle:{"height":"200px"},attrs:{"src":_vm.$store.getters['app/domainHost'] +
                                '/' +
                                _vm.hintfileUrl}})]),_c('div',{staticClass:"position-relative w-100 h-50 mt-2"},_vm._l((_vm.courseQDto.tags),function(tag,i){return _c('div',{key:i},[_c('div',{staticClass:"-mt-2 flex-column align-content-center justify-content-center w-100"},[_c('div',{staticClass:"lable"},[_c('p',{staticStyle:{"margin-bottom":"-15px"}},[_vm._v(" "+_vm._s("وسم" + " " + (i + 1))+" ")]),_c('div',[(i > 0)?_c('unicon',{staticClass:"mt-1 cursor-pointer",attrs:{"fill":"var(--main-color)","height":"25px","name":"trash-alt"},on:{"click":function($event){return _vm.deleteTags(
                                                    _vm.courseQDto.tags,
                                                    i
                                                )}}}):_vm._e(),(i === 0)?_c('unicon',{staticClass:"mt-1 cursor-pointer",attrs:{"fill":"var(--main-color)","height":"25px","name":"plus"},on:{"click":function($event){return _vm.addTags(_vm.courseQDto.tags)}}}):_vm._e()],1)]),_c('ek-input-text',{ref:"order",refInFor:true,staticStyle:{"width":"80%"},attrs:{"name":'وسم' + i,"rules":[
                                        {
                                            type: 'required',
                                            message:
                                                'اضافة وسم للإختبار مطلوب',
                                        } ]},model:{value:(_vm.courseQDto.tags[i]),callback:function ($$v) {_vm.$set(_vm.courseQDto.tags, i, $$v)},expression:"courseQDto.tags[i]"}})],1)])}),0),_c('div',{staticClass:"position-relative w-100 h-50 mt-2"},_vm._l((_vm.courseQDto.answers),function(tag,i){return _c('div',{key:i},[_c('div',{staticClass:"-mt-2 flex-column align-content-center justify-content-center w-100"},[_c('div',{staticClass:"lable"},[_c('p',{staticStyle:{"margin-bottom":"-15px"}},[_vm._v(" "+_vm._s("جواب" + " " + (i + 1))+" ")]),_c('div',[(i > 0)?_c('unicon',{staticClass:"mt-1 cursor-pointer",attrs:{"fill":"var(--main-color)","height":"25px","name":"trash-alt"},on:{"click":function($event){return _vm.deleteAnswers(
                                                    _vm.courseQDto.answers,
                                                    i
                                                )}}}):_vm._e(),(i === 0)?_c('unicon',{staticClass:"mt-1 cursor-pointer",attrs:{"fill":"var(--main-color)","height":"25px","name":"plus"},on:{"click":function($event){return _vm.addAnswers(
                                                    _vm.courseQDto.answers
                                                )}}}):_vm._e()],1)]),_c('div',{staticClass:"d-flex justify-content-between align-content-center"},[_c('ek-input-text',{ref:"order",refInFor:true,staticStyle:{"width":"65%"},attrs:{"name":'جواب' + (i + 1),"placeholder":'جواب' + i,"rules":[
                                            {
                                                type: 'required',
                                                message:
                                                    'اضافة جواب للإختبار مطلوب',
                                            } ]},model:{value:(_vm.courseQDto.answers[i].text),callback:function ($$v) {_vm.$set(_vm.courseQDto.answers[i], "text", $$v)},expression:"courseQDto.answers[i].text"}}),_c('div',{staticClass:"d-flex justify-content-start align-content-center"},[_c('b-form-checkbox',{staticClass:"mx-2 mt-6",staticStyle:{"margin-top":"20px"},attrs:{"name":"صحيح","lable":"صحيح","switch":"","inline":""},model:{value:(
                                                _vm.courseQDto.answers[i]
                                                    .isCorrect
                                            ),callback:function ($$v) {_vm.$set(_vm.courseQDto.answers[i]
                                                    , "isCorrect", $$v)},expression:"\n                                                courseQDto.answers[i]\n                                                    .isCorrect\n                                            "}}),(
                                                _vm.courseQDto.answers[i]
                                                    .isCorrect
                                            )?_c('p',{staticStyle:{"margin-top":"20px","margin-right":"-15px","color":"var(--main-color)"}},[_vm._v(" صحيح ")]):_vm._e(),(
                                                !_vm.courseQDto.answers[i]
                                                    .isCorrect
                                            )?_c('p',{staticStyle:{"margin-top":"20px","margin-right":"-15px","color":"red"}},[_vm._v(" خطأ ")]):_vm._e()],1)],1)])])}),0)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }